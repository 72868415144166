.mx-input {
  padding: 0.5rem 1rem;
  border: 1px solid $color-orange-opacity;
  background: transparent;
  border-radius: 4rem;
  font-family: sans-serif;
  color: $color-purple;
  transition: 0.3s;

  &:focus-visible,
  &:focus,
  &:focus-within {
    border: 2px solid $color-orange;
    outline: none;
  }

  &::placeholder {
    color: $color-orange-opacity;
  }

  &--pink {
    border-color: $color-pink;
    &:focus-visible,
    &:focus,
    &:focus-within {
      border: 2px solid $color-pink;
      outline: none;
    }

    &::placeholder {
      color: $color-pink-opacity;
    }
  }

  &--purple {
    border-color: $color-purple-opacity;
    &:focus-visible,
    &:focus,
    &:focus-within {
      border: 2px solid $color-purple;
      outline: none;
    }

    &::placeholder {
      color: $color-purple-opacity;
    }
  }
}

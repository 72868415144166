.blog-page {
  display: flex;
  align-items: center;
  gap: 2.3rem;
  flex-direction: column;
  margin: 0 auto;
  background-color: $color-white;

  .blog-page__image {
    width: 100%;

    @include custom-media(xs) {
      width: 95%;
    }
  }

  .blog-page__title {
    color: $color-black;
    text-align: center;
    font-weight: 800;
    width: 70%;

    @include custom-media(sm) {
      width: 100%;
    }
  }

  .blog-page__content {
    font-family: sans-serif;
    padding: 5em;
    line-height: 1.8;
    font-weight: 600;
    color: $color-black;

    @include custom-media(xs) {
      padding: 1rem;
    }
  }
}

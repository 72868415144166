.user-comment-post-form {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  width: 100%;
  border-radius: 1rem;
  background-color: $color-white;

  @include custom-media(xs) {
    gap: 0.7rem;
  }

  .mx-input {
    flex-grow: 1;

    @include custom-media(xs) {
      padding: 0.3rem 0.6rem;
    }
  }

  .mx-button {
    @include custom-media(xs) {
      padding: 0.3rem 0.6rem;
    }
  }
}

.user-comment-post__avatar {
  width: 4rem;

  @include custom-media(xs) {
    width: 3rem;
  }
}

.message {
  width: fit-content;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  background-color: $color-white;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 2rem;
  z-index: 999999999;
  animation: show 0.4s;
  box-shadow: 2px 2px 12px $color-purple-opacity;
  min-width: 10rem;
  max-width: 20rem;

  & .message__text {
    color: $color-purple;
    margin: 0;
  }

  & .bxs-check-circle {
    color: $color-orange;
    font-size: 2rem;
  }

  & .bxs-error-circle {
    font-size: 2rem;
    color: $color-pink;
  }
}

@keyframes show {
  from {
    transform: translateY(-40rem);
  }
}

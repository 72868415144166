@mixin custom-media($breakpoint) {
  @if $breakpoint == "xs" {
    @media (max-width: 575.98px) {
      @content;
    }
  } @else if $breakpoint == "sm" {
    @media (max-width: 767.98px) {
      @content;
    }
  } @else if $breakpoint == "md" {
    @media (max-width: 991.98px) {
      @content;
    }
  } @else if $breakpoint == "lg" {
    @media (max-width: 1199.98px) {
      @content;
    }
  } @else if $breakpoint == "xl" {
    @media (min-width: 1200px) {
      @content;
    }
  } @else {
    @error "Invalid breakpoint: #{$breakpoint}. Please use one of: xs, sm, md, lg, or xl.";
  }
}

.port-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @include custom-media(xs) {
    padding: 0;
    height: 100%;
  }
}

@import "./variables";

@import "./components/Admin/admin";
@import "./components/About/about";
@import "./components/Blog/blog";
@import "./components/blogs/blogs";
@import "./components/breath/breath";
@import "./components/button/button";
@import "./components/cart/cart";
@import "./components/checkout/checkout";
@import "./components/comments/comments";
@import "./components/footer/footer";
@import "./components/form/input/input";
@import "./components/form/radio/radio";
@import "./components/gallery/gallery";
@import "./components/home/home";
@import "./components/loading/loading";
@import "./components/menu-aside/menu-aside";
@import "./components/message/message";
@import "./components/modal/modal";
@import "./components/navbar/navbar";
@import "./components/product/product";
@import "./components/product-item/product-item";
@import "./components/profile/profile";
@import "./components/project/project";
@import "./components/replies/replies";
@import "./components/shop/shop";
@import "./components/sign/sign";
@import "./components/user-comment-post/user-comment-post";

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #563a5177;
  z-index: 10000;

  .modal-body {
    background-color: $color-light-pink;
    padding: 2rem;
    border-radius: 1rem;
    min-width: 700px;
    max-width: 400px;
    animation: popup 0.3s;

    @include custom-media(sm) {
      width: 90%;
      min-width: unset;
      padding: 1.6rem;
    }

    &--white {
      background-color: $color-white;
    }
  }
}

@keyframes popup {
  from {
    opacity: 0;
    transform: scale(0);
  }
}

.product-item {
  width: 14rem;
  position: relative;
  text-decoration: none;

  &:hover > .product-item__logo-badge {
    transform: rotateY(90deg);
  }

  @include custom-media (xs) {
    width: 20rem;
  }
}

.product-item__image {
  border-radius: 1rem;
  width: 100%;
}

.product-item__title {
  color: $color-black;
  font-family: sans-serif;
  margin-top: 1rem;
}

.product-item__price {
  color: $color-black;
  font-family: sans-serif;
}

:root {
  --c1: #fc7e29;
  --c3: #ffdcc5;
  --c4: #e22758;
  --c5: #563a51;
}

@font-face {
  font-family: vintage;
  src: url(./fonts/vintage-culture-vintage-culture-400.otf);
}
@font-face {
  font-family: gasq;
  src: url(./fonts/Gasq.otf);
}

body {
  margin: 0;
  font-family: sans-serif, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--c3) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-fluid,
.container,
.app {
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0;
}
.row {
  padding: 0 !important;
  margin: 0;
}

* {
  transition: 0.5s;
  padding: 0;
}
.empty-btn {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sticky {
  position: fixed;
  top: 0;
}
.admin-panel {
  font-family: "cairo";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

.blogs-page {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-family: vintage;
}

.blogs-page__land {
  display: flex;
  width: 100%;
  height: 85vh;
  background-color: var(--c3);
  gap: 4rem;
  border-bottom: 4px solid $color-black;

  @include custom-media(sm) {
    margin-top: 18rem;
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
  }

  @include custom-media(xs) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
    margin-top: 0;
  }
}

.blog-icon {
  width: 35%;
  margin: 7em;

  @include custom-media(sm) {
    width: 50%;
    margin: 0;
    margin-top: 7em;
  }
}

.blogs-page__land-titles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;

  & h1:first-child {
    color: $color-pink;
    font-size: 6rem;
  }

  & h1:nth-child(2) {
    color: $color-black;
    font-size: 6rem;
  }

  & h1:nth-child(3) {
    color: $color-mixed-orange-pink;
    font-size: 3rem;
  }

  @include custom-media(xs) {
    & h1:first-child {
      font-size: 3rem;
    }

    & h1:nth-child(2) {
      font-size: 3rem;
    }

    & h1:nth-child(3) {
      font-size: 2rem;
    }
  }
}

.blogs-page__header {
  color: $color-pink;
  text-align: center;
}

.blogs-page__blogs-section {
  margin: 2rem 0;
}

.blogs-page__no-blogs-message {
  color: $color-purple;
  text-align: center;
}

.blogs-page__blogs-container,
.home-page__blog-section {
  font-family: sans-serif;
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .blogs-page__blog-teaser {
    width: 30rem;
    overflow: hidden;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    border: 3px solid $color-black; 
    position: relative;
    height: 280px;

    @include custom-media(md) {
      width: 90%;
      height: 180px;
    }

    &:hover > .blogs-page__blog-teaser-info {
      opacity: 1;
    }

    .blogs-page__blog-teaser-info {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: $color-pink-opacity;
      opacity: 0;
      transition: 0.3s;
      padding: 1rem;
      box-sizing: border-box;
    }

    .blogs-page__blog-teaser-title {
      font-size: 2rem;
      font-weight: 800;
      color: $color-white;
    }

    & .blogs-page__blog-teaser-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.shop-page {
  display: flex;
  flex-direction: column;
  font-family: vintage;
  overflow: hidden;
}

.shop-page__hero {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  background-color: $color-pink;

  @include custom-media(md) {
    padding: 0;
    padding: 1rem 0;
    flex-direction: column-reverse;
  }

  .shop-page__hero-content {
    position: relative;
    width: 70%;

    @include custom-media(md) {
      width: 100%;
    }
  }

  .shop-page__hero-header {
    width: 80%;
    position: relative;
    z-index: 2;

    @include custom-media(md) {
      display: none;
    }
  }

  .shop-page__hero-header-mobile {
    display: none;
    width: 110%;

    @include custom-media(md) {
      display: block;
    }
  }

  .shop-page__hero-select-square {
    position: absolute;
    width: 300px;
    top: 0;
    right: 25%;

    @include custom-media(md) {
      display: none;
    }
  }

  .shop-page__hero-cursor-clicked {
    position: absolute;
    width: 150px;
    bottom: 0;
    right: 25%;

    @include custom-media(md) {
      width: 75px;
      bottom: -22px;
    }
  }

  .shop-page__hero-left {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10rem;
    flex-grow: 1;

    @include custom-media(md) {
      gap: 0;
    }
  }

  .shop-page__hero-sand-watch {
    transform: rotate(-45deg);
    width: 140px;

    @include custom-media(md) {
      width: 80px;
      transform-origin: center;
      margin-right: 13rem;
    }
  }
}

.shop-page__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $color-white;
  padding: 0.5rem 0.5rem 0 1rem;
  align-items: center;

  .bxs-category {
    font-size: 3rem;
    cursor: pointer;
    color: $color-pink;
  }
}

.shop-page__cart-icon {
  width: 5rem;
}

.shop-page__products-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem 0;

  @include custom-media(xs) {
    flex-direction: column;
  }

  .shop-page__product-wrapper {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include custom-media(xs) {
      width: 100%;
    }
  }
}

.shop-page__categories {
  font-family: sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.shop-page__categories-items {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.shop-page__categories-title {
  font-size: 3rem;
  font-weight: 600;
}

.shop-page__category {
  display: flex;
  gap: 1rem;
  align-items: center;
  min-width: 45%;
  flex-grow: 1;
  padding: 1rem;
  border-radius: 1rem;
  background-color: $color-white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $color-pink;
  }

  .shop-page__category-icon {
    width: 3rem;
    height: 3rem;
  }

  .shop-page__category-title {
    font-size: 1.5rem;
    color: $color-pink;
  }
}

.navbar__wrapper {
  width: 100%;
  background-color: $color-white;
}

.navbar {
  // width: 100%;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: sans-serif;
  text-transform: uppercase;
  z-index: 9999;
  flex-wrap: nowrap;
  position: relative;
  padding: 1rem 4rem;
  box-sizing: border-box;

  @include custom-media(md) {
    padding: 1rem 1.5rem;
  }

  .navbar__logo-img {
    width: 90px;
  }

  .navbar__navs {
    display: flex;
    width: 80%;
    justify-content: space-evenly;

    @include custom-media(md) {
      display: none;
    }
  }

  .navbar__nav {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .navbar__nav-link {
      color: $color-purple;
      font-weight: 200;
      font-size: 18px;
      text-decoration: none;

      &--active {
        color: $color-pink;
        border-bottom: 1px solid $color-pink;
      }
    }
  }

  .navbar__menu {
    padding: 0;
    color: $color-pink;
  }

  & .mx-button {
    padding: 0;;
  }
}

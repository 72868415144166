.home-page__land {
  width: 100%;
  height: 85vh;
  background-color: $color-light-pink;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
}

.home-page__land-waves-1 {
  position: absolute;
  top: 0%;
  left: -3%;
  min-width: 25rem;
  transform: rotateY(180deg);
  z-index: 3;

  @include custom-media(md) {
    top: 0;
    left: -3%;
    min-width: 14rem;
  }
}

.home-page__land-waves-2 {
  position: absolute;
  top: 0%;
  right: -3%;
  z-index: 3;
  min-width: 25rem;

  @include custom-media(md) {
    top: 0;
    right: -3%;
    min-width: 14rem;
  }
}

.home-page__land-bows-1 {
  position: absolute;
  bottom: -3px;
  left: 7%;
  min-width: 22rem;
  z-index: 2;
  transform: rotateY(180deg);

  @include custom-media(md) {
    bottom: -1%;
    left: 1rem;
    min-width: 10rem;
  }
}

.home-page__land-bows-2 {
  position: absolute;
  bottom: -3px;
  right: 4rem;
  min-width: 22rem;
  z-index: 2;

  @include custom-media(md) {
    bottom: -1%;
    right: 7%;
    min-width: 10rem;
    left: unset;
  }
}

.home-page__land-bush-1 {
  position: absolute;
  bottom: -3px;
  left: 0%;
  min-width: 16rem;
  transform: rotateY(180deg);
  z-index: 3;

  @include custom-media(md) {
    bottom: -1%;
    left: 0;
    min-width: 7rem;
  }
}

.home-page__land-bush-2 {
  position: absolute;
  bottom: -3px;
  right: 0;
  min-width: 16rem;
  z-index: 3;

  @include custom-media(md) {
    bottom: -1%;
    right: 0;
    min-width: 7rem;
    width: 7rem;
  }
}

.home-page__land-logo-1 {
  position: absolute;
  bottom: 15%;
  left: 2%;
  min-width: 10rem;
  transform: rotate(226deg);
  z-index: 1;

  @include custom-media(md) {
    bottom: 7%;
    left: 0;
    width: 6rem;
    min-width: 5rem;
  }
}

.home-page__land-logo-2 {
  position: absolute;
  bottom: 15%;
  right: 2%;
  min-width: 10rem;
  transform: rotate(-226deg);
  z-index: 1;

  @include custom-media(md) {
    bottom: 7%;
    right: 0rem;
    width: 6rem;
    min-width: 4rem;
  }
}

.home-page__land-main-logo {
  width: 30rem;

  @include custom-media(md) {
    width: 22rem;
  }

  @include custom-media(sm) {
    width: 15rem;
  }
}

.home-page__discover-section {
  width: 100%;
  background-color: $color-orange;
  position: relative;
  display: flex;
  overflow: hidden;

  @include custom-media(md) {
    flex-direction: column-reverse;
    justify-content: center;
  }
}

.home-page__discover-section-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  margin-left: 5rem;
  font-family: vintage;
  text-transform: uppercase;
  padding: 4rem;

  @include custom-media(md) {
    width: 100%;
    align-items: center;
    margin: 0;
    transform: translateY(-10rem);
    padding: 0;
    padding-bottom: 2rem;
  }

  @include custom-media(sm) {
    transform: translateY(0);
  }

  & span:nth-child(1) {
    font-size: 7rem;
    color: $color-white;

    @include custom-media(md) {
      font-size: 6rem;
    }
    @include custom-media(xs) {
      font-size: 3rem;
    }
  }

  & span:nth-child(2) {
    font-size: 4rem;
    color: $color-black;
    margin-top: 1rem;
    text-wrap: balance;

    @include custom-media(md) {
      font-size: 4rem;
      margin-top: 0.5rem;
    }
    @include custom-media(xs) {
      font-size: 2rem;
      margin-top: 0.5rem;
    }
  }

  & span:nth-child(3) {
    font-size: 5rem;
    color: $color-light-pink;
    margin-top: 1rem;
    text-wrap: nowrap;

    @include custom-media(md) {
      font-size: 3.5rem;
      margin-top: 0.5rem;
    }
    @include custom-media(xs) {
      font-size: 1.5rem;
      margin-top: 0.5rem;
    }
  }

  .mx-button {
    margin-top: 2rem;
  }
}

.home-page__discover-eye {
  width: 50%;

  @include custom-media(md) {
    width: 90%;
    transform: rotate(90deg);

    .eye-svg {
      transform: rotate(90deg) translate(-90px, 30px);
      transform-origin: center;
    }
  }
}

.home-page__discover-section-title {
  z-index: 2;
  text-align: center;

  @include custom-media(md) {
    font-size: 2rem;
  }
}

.home-page__discover-star {
  width: 35%;
  position: absolute;
  top: 75%;
  left: -5%;
  transform: translateY(-50%);
  z-index: 1;
  filter: drop-shadow(15px 0px 0 #000);

  @include custom-media(md) {
    display: none;
  }
}

.home-page__great-things {
  border-top: 4px solid $color-black;
  position: relative;
  background-color: $color-pink;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120vh;
  overflow: hidden;
  width: 100%;

  @include custom-media(md) {
    display: none;
  }
}

.home-page__great-things-tab {
  width: 70%;
}

.home-page__great-things-sand-watch {
  position: absolute;
  top: 40%;
  left: 5%;
  width: 7rem;
}

.home-page__great-things-heart {
  position: absolute;
  top: 50%;
  right: 9%;
  width: 8rem;
}

.home-page__shop {
  width: 100%;
  display: flex;
  margin-block: 10rem;
  background-color: $color-white;
  border-block: 5px solid $color-black;

  @include custom-media(md) {
    flex-direction: column-reverse;
    margin: 0;
    padding: 3rem 0;
  }
}

.home-page__shop-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-family: vintage;
  text-transform: uppercase;
  align-items: center;

  @include custom-media(md) {
    margin-left: 0;
  }

  .mx-button {
    margin-top: 2rem;
    margin-inline: auto;
  }

  @include custom-media(md) {
    width: 100%;
  }
}

.home-page__shop-title {
  font-size: 5rem;
  color: $color-black;
  margin-top: 1rem;
  line-height: 1.5;
  text-wrap: wrap;

  @include custom-media(sm) {
    font-size: 2rem;
    margin-top: 0.5rem;
    text-align: center;
  }
}

.home-page__shop-heart {
  width: 7rem;
  position: absolute;
  top: 12rem;
  left: 50%;

  @include custom-media(md) {
    display: none;
  }
}

.home-page__shop-image {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  border-left: 5px solid $color-black;

  @include custom-media(md) {
    border: none;
    width: 100%;
    padding: 0;
  }
}

.home-page__shop-banner {
  width: 50%;
}

.home-page__feedback-slider {
  width: 100%;
  height: 60vh;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;

  @include custom-media(md) {
    height: 80vh;
  }
}

.home-page__feedback {
  width: 50%;
  padding: 1.5rem;
  position: relative;
  display: flex;
  gap: 2rem;
  align-items: center;
  background-color: $color-white;
  border-radius: 1.5rem;
  border: 3px solid;

  @include custom-media(md) {
    width: 80%;
    height: 140px;
  }
}

.home-page__feedback-body-image {
  width: 7rem;
  border-radius: 100rem;

  @include custom-media(md) {
    width: 5rem;
  }
}

.home-page__feedback-activity-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-page__feedback-username {
  font-size: 1.5rem;
  font-weight: 700;
  color: $color-mixed-orange-pink;
}

.home-page__feedback-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .home-page__feedback-content {
    @include custom-media(md) {
      font-size: 0.6rem;
    }
  }
}

.home-page__feedback-star:first-child {
  position: absolute;
  top: -30%;
  z-index: 1;
  left: -160px;
  width: 20rem;

  @include custom-media(md) {
    width: 10rem;
    left: -3rem;
    top: 1rem;
  }
}

.home-page__feedback-star:nth-child(2) {
  position: absolute;
  bottom: -30%;
  z-index: 1;
  right: -160px;
  width: 20rem;

  @include custom-media(md) {
    bottom: -13%;
    z-index: 1;
    right: -5rem;
    width: 10rem;
  }
}

.home-page__blog-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 6rem;
  padding-block: 2rem;
  background-color: $color-mixed-orange-pink;
}

.home-page__blog-section-heart {
  width: 7rem;
  position: absolute;
  top: -8%;
  margin-inline: auto;

  @include custom-media(md) {
    width: 6rem;
    position: absolute;
    top: -3rem;
  }
}

.ant-carousel {
  width: 100%;
}

.blog-section__blog-slide {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10rem;
  align-items: center;
  height: 70vh;

  @include custom-media(md) {
    flex-direction: column;
    gap: 3rem;
  }
}

.comments {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
  width: 60%;

  @include custom-media(xs) {
    width: 100%;
    padding: 1rem;
  }
}

.comment {
  display: flex;
  width: 85%;
  position: relative;
  padding: 0.5em;
  gap: 0.4rem;
  flex-wrap: wrap;
  border-top: 1px solid $color-purple;

  .comment__avatar {
    width: 3rem;
    height: fit-content;
  }

  .comment__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 80%;
  }

  .comment__username {
    font-size: 1rem;
    color: $color-purple;
    font-family: sans-serif;
  }

  .comment__content {
    font-family: sans-serif;
    font-size: 13px;
  }

  .comment__date {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    color: $color-purple-opacity;
    font-size: 11px;
    font-family: sans-serif;
  }

  .comment__replies-button {
    border: none;
    color: $color-purple;
    width: fit-content;
    background: transparent;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    gap: 0.4rem;

    &.comment__replies-button--open {
      color: $color-pink;
    }
  }
}

.project-page {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;

  .project-page__title {
    font-size: 3rem;
    margin-top: 2rem;
  }

  .project-page__images {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .project-page__image {
    width: 100%;
  }
}

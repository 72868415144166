.cart-page {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  font-family: sans-serif;
  align-items: center;

  & > .upper-work-splash2 {
    position: absolute;
    top: 18rem;
    right: 3rem;
    transform: rotate(90deg) !important;
    left: 43rem;
    width: 71rem;
    z-index: -1;
  }

  .cart-page__header {
    font-size: 4rem;
    color: $color-black;
    text-align: center;
    font-family: vintage;
  }

  .cart-page__empty-cart-image {
    width: 40%;
  }

  .cart-page__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 50%;

    @include custom-media(sm) {
      width: 95%;
      padding: 0;
    }
  }

  .cart-page__items {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    overflow: hidden;
    padding: 0;
    width: 100%;
  }

  .cart-page__cart-info {
    width: 100%;
    padding: 1rem;
    background-color: $color-white;
    border-radius: 3rem;

    .cart-page__total {
      text-align: center;
      font-size: 2rem;
      color: $color-black;
    }
  }
}

.cart-item {
  width: 100%;
  background-color: $color-white;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  position: relative;
  border-bottom: 1px solid $color-purple-opacity;

  .bxs-trash {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: $color-mixed-orange-pink;
    font-size: 1rem;

    @include custom-media(sm) {
      font-size: 2rem;
    }
  }

  .cart-item__image {
    width: 20%;
    @include custom-media(sm) {
      width: 30%;
      height: 100%;
    }
  }

  .cart-item__body {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .cart-item__title {
    font-size: 2rem;
    color: $color-black;
    font-weight: 700;
    @include custom-media(sm) {
      font-size: 1.4rem;
    }
  }

  .cart-item__price {
    display: flex;
    gap: 1rem;
    color: $color-pink;

    & span {
      color: $color-black;

      &.cart-item__price--discount {
        text-decoration: line-through !important;
      }
    }
  }

  .cart-item__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .cart-item__qty {
      font-weight: 700;
      color: $color-black;
    }

    .cart-item__total {
      font-weight: 700;
      color: $color-pink;
    }
  }
}

.breath-page {
  position: relative;
  padding: 4rem 0;
  min-height: 100vh;

  .breath-page__share {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 88;
    background-color: $color-white;
    padding: 1rem;
    border-radius: 44rem;
    height: fit-content;

    .mx-button {
      padding: 0.5rem;
      font-size: 3rem;
      line-height: 0;
      min-width: fit-content;
    }

    @include custom-media(xs) {
      top: 88%;
      right: 1rem;
      padding: 0.3rem;
    }
  }

  .breath-page__posts {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    width: 100%;
  }

  .breath-page__no-posts-message {
    text-align: center;
  }
}

.breath-post {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  width: 35rem;
  padding: 1rem;
  border: 0.2rem solid;
  background-color: $color-white;
  border-radius: 1rem;
  box-sizing: border-box;

  @include custom-media(sm) {
    width: 85%;
    overflow: hidden;
  }

  .breath-post__info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
    position: relative;

    .breath-post__more-delete-btn {
      appearance: none;
      background-color: transparent;
      border: none;
      font-size: 1rem;
      color: $color-pink;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .breath-post__user-info,
  .breath-post__icons {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 0;
  }

  .breath-post__avatar {
    width: 3rem;
  }

  .breath-post__username {
    color: $color-purple;
    font-family: sans-serif;
    margin: 0;
  }

  .breath-post__more {
    background-color: transparent;
    padding: 0;
    width: fit-content;
    border: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-direction: row-reverse;
    cursor: pointer;
  }

  .breath-post__date {
    color: $color-purple-opacity;
    font-family: sans-serif;
    margin: 0;
    font-size: 0.8rem;
    margin-left: auto;
  }

  .breath-post__description {
    color: black;
    width: 95%;
  }

  .breath-post__image {
    width: 33rem;
    height: 33rem;
    border-radius: 1rem;
    cursor: pointer;
    border: 1px solid $color-purple-opacity;
    object-fit: cover;

    @include custom-media(xs) {
      width: 22rem;
      height: 22rem;
      border-radius: 0;
    }
  }

  .breath-post__icons {
    width: 100%;
    padding-inline: 1rem;

    i {
      color: $color-purple;
      font-size: 2rem;
    }
  }

  .breath-post__likes {
    color: $color-purple;
    font-family: sans-serif;
    width: 100%;
    margin: 0;
    padding-left: 1rem;
  }
}

.breath-page .breath-post-preview {
  .modal-body {
    max-width: 60rem;
    overflow: hidden;
    padding: 0.5rem;
  }

  .comments {
    width: 95%;
    height: 100%;
  }

  .replies .user-comment-post-form {
    @include custom-media(sm) {
      transform: translateX(-3rem);
    }
  }

  .breath-post-preview__body {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    justify-content: space-between;

    @include custom-media(sm) {
      flex-direction: column;
      gap: 1rem;
      overflow: auto;
    }
  }

  .breath-post__data {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .breath-post-preview__image {
    width: 48%;
    border-radius: 1rem;

    @include custom-media(sm) {
      width: 25rem;
      height: 25rem;
      object-fit: cover;
      display: none;
    }
  }

  .breath-post__info {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    height: 35rem;
    overflow: auto;

    @include custom-media(sm) {
      width: 100%;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .breath-post__likes,
  .breath-post__description,
  .breath-post__icons {
    padding: 0;
  }

  .bx:active {
    transform: scale(1.2) rotateY(4000deg);
    transition: 0.8s;
  }
  .bx-heart {
    cursor: pointer;
  }

  .bxs-heart {
    color: $color-pink;
    animation: liked 1s;
  }

  @keyframes liked {
    from {
      transform: scale(1.4);
    }
  }

  .user-comment-post-form {
    @include custom-media(xs) {
      gap: 0.3rem;
    }

    .user-comment-post__avatar {
      @include custom-media(xs) {
        width: 3rem;
      }
    }
  }
}

.upload-breath {
  .modal-body {
    height: 40rem;
    overflow: auto;

    @include custom-media(sm) {
      height: 100vw;
    }
  }
}

.upload-breath__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  height: 100%;
}

.upload-breath__head {
  color: $color-purple;
  text-transform: uppercase;
  font-family: sans-serif;
}

.upload-breath__image-uploader {
  width: 80%;

  &::-webkit-file-upload-button {
    width: 100%;
    height: 9rem;
    cursor: pointer;
    border: 5px dashed $color-orange;
    border-radius: 1rem;
    background-color: transparent;
    transition: 0.5s;
    &:hover {
      background-color: $color-orange-opacity;
    }
  }
}

.upload-breath__description {
  width: 80%;
  background-color: transparent;
  color: $color-purple;
  border: 1px solid $color-orange;
  border-radius: 1rem;
  padding: 1rem;
  font-family: sans-serif;
  outline: none;

  &::placeholder {
    color: $color-orange-opacity;
  }
}

.upload-breath__buttons-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: auto;
  margin-bottom: 1rem;
  align-items: center;
}

.upload-breath__preview {
  width: 80%;
  border-radius: 1rem;
}

.breath-post__delete-modal-msg {
  text-align: center;
  color: $color-purple;
}

.breath-post__delete-modal-actions {
  display: flex;
  gap: 2rem;
  justify-content: center;
  width: 100%;
}

.upload-breath__load-drop {
  width: 100%;
  height: 100%;
  background-color: $color-orange-opacity;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.breath-post-preview__close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: none;
  @include custom-media(sm) {
    display: block;
  }
}

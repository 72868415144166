.admin-panel {
  .ant-tabs-nav-list {
    padding: 1rem;
  }
  .admin-panel__header {
    font-size: 6rem;
    text-align: center;
  }
  .admin-panel__title {
    text-align: center;
    color: $color-pink;
    font-family: sans-serif;
  }

  .admin-panel__header {
    color: $color-purple;
    font-family: sans-serif;
  }

  .admin-panel__blog-form {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;
    background-color: $color-orange-opacity;
  }

  .admin-panel__product-form {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;
    background-color: $color-orange-opacity;
  }

  .admin-panel__project-form {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;
    background-color: $color-orange-opacity;
  }
}

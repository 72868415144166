$color-pink: #e22758;
$color-purple: #563a51;
$color-orange: #fc7e29;
$color-light-pink: #ffdcc5;
$color-mixed-orange-pink: #ff5052;
$color-white: #fff;
$color-black: #000;

$color-orange-opacity: #fc7d2992;
$color-pink-opacity: #e2275981;
$color-purple-opacity: #563a5193;
$color-light-pink-opacity: #ff505397;

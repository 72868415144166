.product-page-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .product-page {
    display: flex;
    gap: 5rem;
    font-family: sans-serif;
    margin-top: 4rem;

    @include custom-media(xs) {
      flex-direction: column;
    }

    .product-page__image-wrapper {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @include custom-media(xs) {
        width: 100%;
      }

      .product-page__image {
        width: 70%;
        border-radius: 1rem;
        border: 1px solid $color-purple-opacity;

        @include custom-media(xs) {
          width: 95%;
        }
      }
    }

    .modal-body {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
    }

    .product-page__info {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @include custom-media(xs) {
        width: 100%;
        padding-left: 0;
      }
    }

    .product-page__title {
      font-size: 3rem;
      color: #000;
    }

    .product-page__price {
      display: flex;
      gap: 1rem;
      color: $color-pink;
      align-items: center;

      & span {
        color: $color-purple;

        &.product__price--discount {
          text-decoration: line-through !important;
        }
      }

      .product-page__discount {
        width: 5rem;
        height: 5rem;
        font-size: 0.8rem;
        background-color: $color-pink;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.4rem;
        color: $color-white;
        font-weight: 600;
      }
    }

    .product-page__qty-bar {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .product-page__qty-label {
      color: $color-purple;
      margin: 0;
    }

    .product-page__cart-counter {
      position: absolute;
      top: 50%;
      left: 51rem;
      min-width: 25px;
      min-height: 25px;
      padding: 1px;
      background: $color-pink;
      border-radius: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-white;

      @include custom-media(xs) {
        left: 16rem;
      }
    }

    .product-page__modal-actions {
      display: flex;
      gap: 3rem;
    }

    .product-page__msg-title {
      color: $color-purple;
    }

    .product-page__cart-image {
      width: 7rem;
      animation: shaking 1s;
    }

    @keyframes shaking {
      25% {
        transform: rotate(45deg);
      }
      35% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(45deg);
      }
      75% {
        transform: rotate(0deg);
      }
    }
  }

  .product-page__product-additional-info-title {
    font-size: 1.5rem;
    margin-top: 3rem;
  }

  .product-page__product-additional-info {
    width: 90%;
    padding: 0.5rem;

    & img {
      width: 16rem;
      height: 16rem;
      border-radius: 0.5rem;
      padding-left: 2rem;
    }
  }
}

.checkout-page {
  display: flex;
  align-items: center;
  gap: 3rem;
  flex-direction: column;

  .bxs-trash {
    display: none;
  }

  .cart-item {
    border-radius: 1rem;

    .cart-item__image {
      border-radius: 1rem;
    }
  }
}

.checkout-page__header,
.total-price {
  color: $color-purple;
}

.checkout-page__items {
  font-family: sans-serif;
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.downloads {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
}

.downloads__header {
  font-size: 44px;
  color: $color-purple;
  text-align: center;
}

.downloads__container {
  width: 600px;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.downloads__card {
  width: 100%;
}

.checkout-summary {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: $color-white;
  padding: 1rem;
  border-radius: 1rem;
  width: 50%;
  
  @include custom-media(xs) {
    width: 90%;
  }
  
  .checkout-summary__header {
    color: $color-purple;
    font-size: 2rem;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .total-price {
    font-size: 1.4rem;
    font-weight: 600;
    color: $color-pink;
  }

  .checkout-summary__info {
    display: flex;
    align-items: center;
    gap: 1rem;

    .checkout-summary__info-title {
      color: $color-purple;
      font-size: 1.3rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

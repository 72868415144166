.gallery-page {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;

  .gallery-page__hero {
    width: 100%;
    background-color: $color-orange;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    padding: 3rem;
    gap: 2rem;
    position: relative;
    overflow: hidden;

    @include custom-media(md) {
      flex-direction: column;
    }

    .gallery-page__hero-content {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding: 2rem;
      gap: 2rem;

      @include custom-media(md) {
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 2rem;
      }
    }

    .gallery-page__hero-image {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include custom-media(md) {
        width: 100%;
      }
    }
    .gallery-page__hero-tabs {
      width: 80%;
    }

    .gallery-page__hero-star {
      position: absolute;
      top: 40%;
      left: 60%;
      width: 18rem;
      filter: drop-shadow(15px 0px 0 #000);

      @include custom-media(md) {
        top: 25%;
        left: 50%;
        width: 12rem;
      }
    }

    .gallery-page__hero-title {
      font-family: vintage;
      font-size: 4rem;
      color: $color-black;
      text-wrap: balance;
      text-transform: uppercase;
      z-index: 34;
      position: relative;

      @include custom-media(md) {
        font-size: 3rem;
        padding-left: 1rem;
      }

      & span:first-child {
        font-size: 6.5rem;

        @include custom-media(md) {
          font-size: 4rem;
        }
      }

      & span:last-child {
        color: $color-light-pink;
      }
    }

    .gallery-page__hero-subtitle {
      color: $color-white;
      font-weight: 100;
      font-size: 2.5rem;
      width: 50%;
      text-wrap: balance;
      font-family: system-ui;
    }
    
  }

  .gallery-page__message {
    color: $color-pink;
    text-align: center;
  }

  .gallery-page__projects {
    width: 100%;
    display: flex;
    gap: 3rem;
    align-items: center;
    flex-direction: column;
    padding: 1rem;

    .gallery-page__project {
      width: 80%;
      position: relative;
      overflow: hidden;
      border-radius: 1rem;
      transition: 0.4s;
      border: 5px solid $color-black;

      @include custom-media(sm) {
        width: 100%;
      }

      &:hover {
        transform: scale(1.1);
      }

      &:hover > .gallery-page__project-visit {
        transform: translate(-94px, -150px);
      }

      .gallery-page__project-image {
        width: 100%;
        object-fit: cover;
      }

      .gallery-page__project-visit {
        width: 100px;
        height: 100px;
        position: absolute;
        bottom: -11rem;
        left: 100%;
        background-color: $color-pink;
        border-radius: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        > i {
          font-size: 3rem;
          color: $color-white;
        }
      }
    }
  }
}

.sign-page {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:has(.register-card) {
    @include custom-media(sm) {
      height: 90vh;
    }
  }

  &:has(.login-card) {
    @include custom-media(sm) {
      height: 70vh;
    }
  }

  .sign-page__header {
    width: 100%;
    padding: 2rem;
  }

  .sign-page__home-link {
    padding-left: 2rem;
  }

  .sign-page__logo {
    width: 5rem;
  }

  .login-card {
    padding: 2rem;
    width: 25rem;
    background-color: $color-white;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: up 0.2s linear;

    @include custom-media(sm) {
      width: 22rem;
      border-radius: 3rem;
    }
  }

  .login-card__head {
    color: $color-pink;
    text-transform: uppercase;
    font-weight: 700;
  }

  .login-card__form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 100%;

    .mx-input {
      width: 100%;
    }
  }

  .login-card__message {
    color: $color-black;

    span {
      color: $color-pink;
      cursor: pointer;
    }
  }

  .register-card {
    padding: 2rem;
    width: 25rem;
    background-color: $color-mixed-orange-pink;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: up 0.2s linear;

    @include custom-media(sm) {
      width: 22rem;
      border-radius: 3rem;
    }
  }

  .register-card__head {
    color: $color-white;
    text-transform: uppercase;
    font-weight: 700;
  }

  .register-card__form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 100%;

    .mx-input {
      width: 100%;
      background-color: $color-white;
    }
  }

  .register-card__message {
    color: $color-white;

    span {
      color: $color-black;
      cursor: pointer;
    }
  }
}

@keyframes up {
  from {
    transform: translateY(40rem);
  }
}

.PhoneInput {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: $color-white;
  border-radius: 44rem;
  padding: 0.5rem;
}

.PhoneInputCountry {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  select {
    width: 90px;
    outline: none;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
  }
}

.PhoneInputCountryIconImg {
  width: 30px;
}

.PhoneInputInput {
  border: none;
  outline: none;
  padding: 0;
  width: 100%;

  &::placeholder {
    color: $color-orange;
  }
}

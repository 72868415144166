.radio {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  &.radio--horizontal {
    flex-direction: row;
  }
}

.radio-option {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.radio-label {
  font-size: 1.2rem;
  color: $color-white;
  cursor: pointer;
}

.radio-control {
  appearance: none;
  width: 20px;
  height: 20px;
  background: $color-white;
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    background-color: $color-pink;
    border-radius: 50%;
    opacity: 0;
    transition: 0.4s;
  }

  &:checked::after {
    opacity: 1;
  }
}

.about-page {
  .about-page__cv {
    padding: 4rem 0;
    display: flex;
    gap: 1rem;
    background-color: $color-white;
    .about-page__cv-section {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;

      @include custom-media(xs) {
        width: 100%;
      }
    }

    @include custom-media(xs) {
      flex-direction: column-reverse;
      gap: 3rem;
    }
  }

  .about-general {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: $color-light-pink;
    border-radius: 1rem;
    padding: 1rem;

    @include custom-media(xs) {
      width: 95%;
    }
  }

  .about-general__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .about-section__skill-progress {
      position: relative;
      left: 0;
    }
  }

  .about-general__header {
    border-radius: 4rem;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-orange;
    color: $color-white;
    font-size: 30px;
    width: 15rem;
    text-transform: uppercase;
  }

  .about-general__item {
    color: $color-purple;
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
  }

  .about-general__social {
    display: flex;
    gap: 3rem;
    overflow: hidden;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .about-general__icon {
    width: 2rem;
  }

  .about-general__items {
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .about-general__hobby {
    font-size: 22px;
    color: $color-purple;
  }

  .about-page__cv-header {
    display: flex;
    justify-content: space-between;
    align-self: center;
    width: 100%;
    padding: 1rem;
  }

  .about-page__logo-header {
    width: 8rem;
  }
}

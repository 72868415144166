.replies {
  padding-left: 1rem;
  width: fit-content;
  border-radius: 1rem;

  .user-comment-post__avatar {
    width: 2rem;
  }
  .mx-input {
    padding: 0.2rem 0.6rem;
  }
  .mx-button {
    min-width: 5rem;
    padding: 0.2rem 0.1rem;
  }

  .replies__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
}

.reply {
  display: flex;
  width: 80%;
  position: relative;
  padding: 0.5em;
  gap: 0.4rem;
  border-top: 1px solid $color-purple;

  .reply__avatar {
    width: 2rem;
    height: fit-content;
  }

  .reply__body {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    width: 80%;
  }

  .reply__username {
    font-size: 0.7rem;
    color: $color-purple;
    font-family: sans-serif;
  }

  .reply__content {
    font-family: sans-serif;
    font-size: 10px;
  }

  .reply__date {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    color: $color-purple-opacity;
    font-size: 8px;
    font-family: sans-serif;
  }
}

.replies__message {
  text-align: center;
  margin-top: 0.5rem;
}

.mx-button {
  width: fit-content;
  border-radius: 100rem;
  text-transform: uppercase;
  font-family: vintage;
  transition: all 0.3s ease-in-out;
  color: $color-white;
  border: none;
  outline: none;
  text-wrap: nowrap;
  cursor: pointer;

  &:hover {
    transform: translateY(-0.3rem);
    opacity: 0.8;
  }

  &:active {
    transform: translateY(0.1rem);
  }

  &.mx-button--orange {
    background-color: $color-orange;
  }

  &.mx-button--pink {
    background-color: $color-pink;
  }

  &.mx-button--black {
    background-color: $color-black;
  }

  &.mx-button--light-pink {
    background-color: $color-light-pink;
  }

  &.mx-button--transparent {
    background-color: transparent;
  }

  &.mx-button--large {
    padding: 1.5rem 3.5rem;
    font-size: 1rem;
  }

  &.mx-button--medium {
    padding: 1rem 2.4rem;
    font-size: 0.9rem;
  }

  &.mx-button--small {
    padding: 1rem 2.4rem;
    font-size: 0.8rem;
  }

  &.mx-button--x-small {
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
  }

  &.mx-button--full-width {
    width: 100%;
  }
}

.footer {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  padding: 3rem 0;

  @include custom-media(md) {
    height: fit-content;
    align-items: center;
  }
}

.footer__content {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  z-index: 3;

  @include custom-media(md) {
    width: 90%;
    align-items: center;
    justify-content: center;
  }
}

.footer__social-icons {
  display: flex;
  gap: 2rem;

  @include custom-media(md) {
    gap: 0.5rem;
  }
}

.footer__icon {
  width: 6rem;

  &:hover {
    transform: scale(1.1);
  }

  @include custom-media(md) {
    width: 3rem;
  }
}

.footer__pages {
  display: flex;
  gap: 2rem;

  @include custom-media(md) {
    gap: 1rem;
  }
}

.footer__page-link {
  font-size: 1.5rem;
  font-weight: 100;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-decoration: none;
  color: $color-black;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }

  @include custom-media(md) {
    font-size: 1rem;
  }
}

.footer .footer__waves-1 {
  position: absolute;
  bottom: 0%;
  right: -3%;
  min-width: 33rem;
  transform: rotateX(180deg);
  z-index: 2;

  @include custom-media(md) {
    min-width: 0rem;
    width: 12rem;
    right: -3%;
  }
}

.footer .footer__waves-2 {
  position: absolute;
  bottom: 0%;
  left: -3%;
  z-index: 2;
  min-width: 33rem;
  transform: rotateX(180deg) rotateY(180deg);

  @include custom-media(md) {
    left: -3%;
    width: 12rem;
    min-width: 0rem;
  }
}

.footer__logo {
  width: 13rem;

  @include custom-media(md) {
    width: 7rem;
  }
}

.menu-aside__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999998;
  background-color: $color-purple-opacity;
  animation: show-container 0.3s;
}

.menu-aside {
  height: 100vh;
  background-color: $color-white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  gap: 1rem;
  border-radius: 0.5rem;
  box-shadow: -3px 0 10px rgba(0, 0, 0, 0.2);
  transition: width 0.2s ease-in-out;
  animation: show-aside 0.3s;
  width: 30%;

  @include custom-media(md) {
    width: 100%;
  }

  .menu-aside__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  & .menu-aside__user-profile {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    text-decoration: none;

    &:hover {
      transform: scale(1.1);
    }

    & .menu-aside__user-profile-avatar {
      width: 5rem;
      border: 0.2rem solid $color-pink;
      border-radius: 100%;
    }

    & .menu-aside__user-profile-username {
      font-size: 1rem;
      color: $color-purple;
      font-weight: 800;
    }
  }

  & .menu-aside__items {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }

  & .menu-aside__item {
    background-color: transparent;
    border: none;

    & .menu-aside__item-link {
      text-decoration: none;
      font-size: 1.4rem;
      font-weight: 800;
      color: $color-pink;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@keyframes show-container {
  from {
    opacity: 0;
  }
}

@keyframes show-aside {
  from {
    transform: translateX(100%);
  }
}

.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .profile-page__header {
    color: $color-purple;
    padding-top: 2rem;
  }

  .profile-page__avatar-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .profile-page__avatar {
    width: 7.5rem;
  }

  .profile-page__greet {
    font-size: 3.8rem;
    color: $color-pink;
  }

  .profile-page__card {
    width: 400px;
    background-color: $color-white;
    border: 1px solid $color-pink;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .profile-page__info {
    font-family: sans-serif;
    color: $color-purple;
  }

  .update-profile-modal__header {
    text-align: center;
    color: $color-purple;
  }

  .update-profile-modal__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .update-profile-modal__options {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: center;
  }

  .update-profile-modal__avatar-option {
    position: relative;
    width: 90px;
    cursor: pointer;

    &::after {
      content: "";
      width: 100px;
      height: 100px;
      border-radius: 4rem;
      background-color: $color-purple;
      transition: 0.5s linear;
      position: absolute;
      top: -5px;
      left: -5px;
      transform: scale(0);

      @include custom-media(xs) {
        width: 85px;
        height: 85px;
      }
    }

    &.update-profile-modal__avatar-option--checked::after {
      transform: scale(1);
    }
  }

  .update-profile-modal__avatar {
    width: 100%;
    position: relative;
    z-index: 20;
  }

  .profile-page__title-posts {
    color: $color-purple;
  }
}
